const useColumns = [
  {
    text: "Imagen",
    align: "start",
    value: "photo",
    class: "header-title",
    sortable: false,
  },
  {
    text: "Nombre",
    align: "start",
    value: "name",
    class: "header-title",
    sortable: false,
  },
  {
    text: "Acción",
    value: "actions",
    sortable: false,
    class: "header-title",
  },
];

export default useColumns;
