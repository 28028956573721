<template>
  <div>
    <v-dialog
      v-model="viewModal"
      max-width="400px"
      persistent
      scrollable
      content-class="admin-dialog edit-mark"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="second"
          class="mb-1 white--text"
          v-bind="attrs"
          v-on="on"
          @click="btnDialog()"
        >
          Registrar
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="card-title">
          <span class="headline">{{ edit ? "Editar" : "Registrar" }}</span>

          <v-spacer></v-spacer>

          <CustomButtonAction
            :btnApply="true"
            :btnCancel="true"
            :loaderApply="loading"
            colorBtnApply="white"
            colorBtnCancel="white"
            @onChangeApply="apply()"
            @onChangeCancel="cancel()"
          />
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <div class="image-center">
                    <input
                      id="filePhoto"
                      type="file"
                      accept="image/jpeg,image/png,.jpeg,.jpg,.png"
                      style="display: none"
                      @change="processWebImage($event)"
                    />
                    <div class="select-image" v-if="!item.photo">
                      <label for="filePhoto" class="center">
                        <v-icon>mdi-tray-arrow-up</v-icon>
                        Seleccionar Imagen
                      </label>
                    </div>
                    <div class="file" v-if="item.photo">
                      <img :src="item.photo | imageDefault" />
                      <div class="btn-delete">
                        <v-btn color="error" small fab @click="deletePhoto()">
                          <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Nombre"
                    v-model="item.name"
                    :autocomplete="'off'"
                    :rules="requiredRules"
                    :maxlength="100"
                    dense
                    color="second"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <CustomCrop
      :dialog.sync="modalCrop"
      :imgSrc.sync="photo"
      @onChange="onChangeCrop($event)"
    />
  </div>
</template>

<script>
import CustomButtonAction from "../../../../shared/components/custom-button-action/CustomButtonAction.vue";
import CustomCrop from "../../../../shared/components/custom-crop/CustomCrop.vue";
import useValidate from "../../../../shared/validate/useValidate";
import MarkServices from "../../../../core/services/mark-services";

export default {
  name: "EditAdmin",
  data: () => ({
    viewModal: false,
    modalCrop: false,
    valid: false,
    loading: false,
    item: { photo: "" },
    photo: "",
    requiredRules: [useValidate["required"]],
  }),
  components: {
    CustomButtonAction,
    CustomCrop,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    data: {},
  },
  watch: {
    dialog: function (value) {
      this.viewModal = value;
    },
    edit: function (value) {
      if (value) {
        this.item = { ...this.data };
      }
    },
  },
  methods: {
    btnDialog() {
      this.item = {};
      this.viewModal = true;
    },
    apply() {
      const validate = this.$refs.form.validate();
      if (validate) {
        if (this.edit) {
          this.btnEdit();
        } else {
          this.btnSave();
        }
      }
    },
    cancel() {
      this.viewModal = false;
      this.$emit("update:dialog", false);
      this.$emit("update:edit", false);
      setTimeout(() => {
        this.$refs.form.reset();
      }, 500);
    },
    btnSave() {
      this.loading = true;
      const { saveMark } = MarkServices();
      saveMark(this.item).then((resp) => {
        const response = resp.data;
        if (response.code == 201) {
          this.$toast.success("La marca se ha registrado de forma exitosa");
          this.$emit("onChange", response.data);
          this.cancel();
        }
        this.loading = false;
      });
    },
    btnEdit() {
      this.loading = true;
      const { updateMark } = MarkServices();
      this.item.typeUser = {
        id: 3,
        name: "Administrador",
      };
      updateMark(this.item.id, this.item).then((resp) => {
        const response = resp.data;
        if (response.code == 201) {
          this.$toast.success("La marca se ha actualizado de forma exitosa");
          this.$emit("onChange", response.data);
          this.cancel();
        }
        this.loading = false;
      });
    },
    processWebImage(event) {
      const reader = new FileReader();
      reader.onload = async (readerEvent) => {
        const image64 = readerEvent.target.result;
        this.item = { ...this.item, photo: image64 };
      };
      reader.readAsDataURL(event.target.files[0]);
    },
    deletePhoto() {
      this.item = { ...this.item, photo: "" };
    },
  },
};
</script>

<style lang="scss">
.edit-mark {
  .image-center .select-image {
    margin-bottom: 10px;
    width: 95%;
  }

  .image-center .file img {
    width: 80%;
    border-radius: 5px;
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 40%);
  }
}
</style>
